<template>
  <Modal ref="modal" title="Details strategisch niveau">
    <div slot="footer">
      <a @click="hide" class="btn btn-default" target="_blank" type="button">Sluiten</a>
    </div>

    <div class="tabs-container" v-if="strategicLevel">
      <ul class="nav nav-tabs">
        <li class="active"><a aria-expanded="true" data-toggle="tab" href="#tab-history">Historiek</a></li>
        <li class=""><a aria-expanded="false" data-toggle="tab" href="#tab-mutation">Budgetverschuiving</a></li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane active" id="tab-history">
          <table class="table table-hover">
            <thead>
            <tr>
              <th>Gebruiker</th>
              <th>Tijdstip</th>
              <th>Actie</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="action in actions" :key="action.id">
              <td>{{ action.user.first_name }} {{ action.user.last_name }}</td>
              <td>{{ action.timestamp|datetime }}</td>
              <td>{{ action.description }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane" id="tab-mutation">
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label class="col-sm-2 control-label">Bedrag</label>
                <div class="col-sm-10">
                  <input class="form-control" type="text" v-model="amount">
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">Naar niveau</label>
                <div class="col-sm-10">
                  <select class="form-control" v-model="destinationLevel">
                    <option :key="level.id" :value="level" v-for="level in budget.strategic_levels">
                      {{ level.type.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-2 control-label">Opmerkingen</label>
                <div class="col-sm-10">
                  <textarea class="form-control" cols="10" rows="4" v-model="comments"></textarea>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-10 col-sm-offset-2">
                  <a @click="mutate" class="btn btn-primary">Verschuiven</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import $ from 'jquery'
import Modal from '../iam/Modal'
import { questionModal, startLoadingModal, stopLoadingModal, errorModal } from '@/modalMessages'
import { mapActions } from 'vuex'

export default {
  name: 'StrategicLevelModal',
  components: { Modal },
  props: ['budget', 'strategicLevel'],
  data () {
    return {
      amount: 0,
      destinationLevel: null,
      comments: '',
      actions: []
    }
  },
  watch: {
    strategicLevel () {
      $.ajax({
        type: 'GET',
        url: '/api/budget/actions?strategic_level=' + this.strategicLevel.id
      }).done((data) => {
        this.actions = data.results
      }).fail((error) => {
        console.error(error)
      })
    }
  },
  methods: {

    ...mapActions('budget', ['mutateBudget', 'loadBudget']),

    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    async reloadBudget () {
      await this.loadBudget(this.budget.id).then(
        () => {
          return Promise.resolve()
        },
        () => {
          return Promise.reject(new Error('Kan budgetgegevens niet herladen gelieve de pagina manueel te herladen'))
        }
      )
    },
    handleReloadBudgetSuccess () {
      stopLoadingModal()
      this.amount = 0
      this.destinationLevel = null
      this.comments = ''
      return Promise.resolve()
    },
    handleMutateBudgetError () {
      return Promise.reject(new Error('Kan budget niet verschuiven gelieve opnieuw te proberen'))
    },
    async mutate () {
      const result = await questionModal('Weet je zeker dat je dit budget wil verschuiven?')
      if (!result.value) return

      startLoadingModal('Budget wordt verschoven...')

      const data = {
        amount: this.amount,
        source_strategic_level: this.strategicLevel.id,
        destination_strategic_level: this.destinationLevel.id,
        comments: this.comments
      }

      await this.mutateBudget(data)
        .then(this.reloadBudget, this.handleMutateBudgetError)
        .then(this.handleReloadBudgetSuccess)
        .catch(e => errorModal(e.message))
    }
  }
}
</script>

<style scoped>

</style>
