<template>
  <Modal title="Details van bestelling" ref="modal">
    <div slot="footer">
      <a @click="hide" class="btn btn-default" target="_blank" type="button">Sluiten</a>
    </div>

    <div v-if="order">
      <h3>Algemeen</h3>
      <dl class="dl-horizontal">
        <dt>Product</dt>
        <dd>{{ order.product.name }}</dd>
        <dt>Beschrijving</dt>
        <dd>{{ order.order_description }}</dd>
        <dt>Datum</dt>
        <dd>{{ order.order_date|date-day }}</dd>
        <dt>Bedrag besteld</dt>
        <dd>&euro; {{ order.amount|currency }}</dd>
        <template v-if="details.created_by">
          <dt>Aangemaakt</dt>
          <dd>{{ details.created_by.first_name }} {{ details.created_by.last_name }}, {{ details.created_on|datetime
            }}
          </dd>
          <dt>Aangepast</dt>
          <dd>{{ details.updated_by.first_name }} {{ details.updated_by.last_name }}, {{ details.updated_on|datetime
            }}
          </dd>
        </template>
      </dl>
      <div v-if="order.distribution_order">
        <h3>Verdeelsleutel bestelling</h3>
        <dl class="dl-horizontal">
          <dt>Verdeelsleutel</dt>
          <dd>{{ order.distribution_order.distribution.department }}</dd>
          <dt>Totaalbedrag</dt>
          <dd>&euro; {{ order.distribution_order.amount | currency}}</dd>
        </dl>
      </div>
      <div v-if="details.invoices && details.invoices.length > 0">
        <h3>Facturen</h3>
        <table class="table table-hover">
          <thead>
          <tr>
            <th>Nummer</th>
            <th>Datum</th>
            <th>Leverancier</th>
            <th>Opmerking</th>
            <th>Bedrag</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="invoice in details.invoices" :key="invoice.id">
            <td>{{ invoice.invoice.number}}</td>
            <td>{{ invoice.invoice.date }}</td>
            <td>{{ invoice.invoice.supplier.name }}</td>
            <td>{{ invoice.invoice.description }}</td>
            <td>&euro; {{ invoice.amount|currency}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '../iam/Modal'

export default {
  name: 'OrderDetailModal',
  components: { Modal },
  props: ['order'],
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    }
  },
  computed: {
    details () {
      return this.order || this.order.distribution_order
    }
  }
}
</script>

<style scoped>

</style>
