<template>
  <Modal title="Details van bestelling" ref="modal">
    <div slot="footer">
      <a @click="hide" class="btn btn-default" target="_blank" type="button">Sluiten</a>
    </div>

    <div class="row" v-if="productOrder">
      <dl class="dl-horizontal">
        <dt>Product</dt>
        <dd>{{ productOrder.product.name }}</dd>
        <dt>Opmerkingen</dt>
        <dd>{{ productOrder.description }}</dd>
        <dt>Bedrag vastgelegd</dt>
        <dd>&euro; {{ productOrder.predetermined_budget|currency }}</dd>

        <dt>Aangemaakt</dt>
        <dd>{{ productOrder.created_by.first_name }} {{ productOrder.created_by.last_name }}, {{
          productOrder.created_on|datetime }}
        </dd>
        <dt>Aangepast</dt>
        <dd>{{ productOrder.updated_by.first_name }} {{ productOrder.updated_by.last_name }}, {{
          productOrder.updated_on|datetime }}
        </dd>
      </dl>
      <div v-if="productOrder.distribution_order">
        <h3>Verdeelsleutel bestelling</h3>
        <dl class="dl-horizontal">
          <dt>Verdeelsleutel</dt>
          <dd>{{ productOrder.distribution_order.distribution.department }}</dd>
          <dt>Totaalbedrag</dt>
          <dd>&euro; {{ productOrder.distribution_order.amount | currency}}</dd>
        </dl>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '../iam/Modal'

export default {
  name: 'ProductOrderDetailModal',
  components: { Modal },
  props: ['productOrder'],
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    }
  }
}
</script>

<style scoped>

</style>
