<template>
  <Modal :title="title" ref="modal">
    <div slot="footer">
      <a @click="saveOrderHandler" class="btn btn-primary" target="_blank" type="button">Bestellen</a>
      <a @click="hide" class="btn btn-default" target="_blank" type="button">Annuleren</a>
    </div>

    <div class="row">
      <div v-if="productOrder">
        <p>Nieuwe bestelling voor <strong>{{ productOrder.product.name }}</strong>: Er is een nog een budget van &euro;
          {{ productOrder.available_budget|currency }} beschikbaar.</p>
        <p><em>{{ productOrder.product.description}}</em></p>
      </div>

      <div class="col-sm-12">
        <div class="form-group">
          <label class="col-sm-2 control-label">Bedrag</label>
          <div class="col-sm-10">
            <input class="form-control" placeholder="Bedrag van de bestelling" type="text"
                   v-model="amount">
          </div>
        </div>
        <div class="form-group" v-if="user.is_staff">
          <label class="col-sm-2 control-label">Verdeelsleutel</label>
          <div class="col-sm-10">
            <select :disabled="order && order.id" class="form-control" v-model="distribution">
              <option value="0">Geen verdeelsleutel bestelling</option>
              <option :key="distribution.id" :value="distribution" v-for="distribution in distributions">{{
                distribution.department }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">Datum</label>
          <div class="col-sm-10">
            <DatePicker v-model="date"></DatePicker>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">Opmerkingen</label>
          <div class="col-sm-10">
            <textarea class="form-control" name="comments" rows="5" v-model="description"></textarea>
          </div>
        </div>
        <div class="form-group">
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import moment from 'moment'
import Modal from '@/components/iam/Modal'
import DatePicker from '@/components/iam/DatePicker'
import { startLoadingModal, stopLoadingModal, errorModal } from '@/modalMessages'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  name: 'NewOrderModal',
  components: { DatePicker, Modal },
  props: ['budget', 'productOrder', 'order'],
  mounted () {
    this.loadDistributions()
  },
  data () {
    return {
      amount: 0,
      date: new Date(),
      description: '',
      distribution: 0
    }
  },
  watch: {
    order () {
      this.amount = this.order ? this.order.amount : ''
      this.date = this.order ? moment(this.order.order_date) : new Date()
      this.description = this.order ? this.order.description : ''
      this.distribution = this.order ? this.order.distribution : 0
    },
    amount () {
      this.amount = this.amount.replace(',', '.')
    }
  },
  computed: {
    ...mapGetters('budget', ['getDistributions']),
    ...mapState('account', ['user']),
    title () {
      return this.order ? 'Bestelling bewerken' : 'Nieuwe bestelling'
    },
    distributions () {
      return this.getDistributions()
    }
  },
  methods: {
    ...mapActions('budget', ['loadDistributions', 'saveOrder', 'loadProductOrders', 'loadBudget']),
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    async reloadProductOrders () {
      const productOrderPromise = this.loadProductOrders(this.budget.id)
      const loadBudgetPromise = this.loadBudget(this.budget.id)
      await Promise.all([productOrderPromise, loadBudgetPromise]).then(
        () => {
          return Promise.resolve()
        },
        () => {
          return Promise.reject(new Error('Er ging iets mis bij het herladen van de bestellingen, gelieve de pagina manueel te herladen'))
        }
      )
    },
    handleSaveOrderError () {
      return Promise.reject(new Error('Kan bestelling niet opslaan, gelieve opnieuw te proberen'))
    },
    handleReloadProductOrdersSuccess () {
      this.hide()
      this.amount = ''
      this.date = new Date()
      this.description = ''
      this.distribution = 0
      stopLoadingModal()
    },
    async saveOrderHandler () {
      const amount = parseFloat(this.amount)
      if (isNaN(amount)) {
        errorModal("De waarde opgeven bij 'bedrag' is geen correct bedrag")
        return
      }
      const order = {
        id: this.order ? this.order.id : null,
        product_order: this.productOrder.id,
        amount: this.amount,
        order_date: moment(this.date).format('YYYY-MM-DD'),
        description: this.description,
        distribution: this.distribution ? this.distribution.id : null
      }

      startLoadingModal('De bestelling wordt opgeslagen...')
      await this.saveOrder({ order: order })
        .then(this.reloadProductOrders, this.handleSaveOrderError)
        .then(this.handleReloadProductOrdersSuccess)
        .catch(e => errorModal(e.message))
    }
  }
}
</script>

<style scoped>
  .product {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .is-warning {
    color: orangered;
  }
</style>
