<template>
  <Modal :title="title" ref="modal">
    <div slot="footer">
      <a @click="determineBudget" class="btn btn-primary" target="_blank" type="button">Vastleggen</a>
      <a @click="hide" class="btn btn-default" target="_blank" type="button">Annuleren</a>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label class="col-sm-2 control-label">Categorie</label>
          <div class="col-sm-10">
            <select :disabled="this.product_order && this.product_order.id" class="form-control" v-model="category">
              <option :key="category.id" :value="category" v-for="category in categories">{{ category.name }}</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">Product</label>
          <div class="col-sm-10">
            <select :disabled="this.product_order && this.product_order.id" class="form-control" v-model="product">
              <option :key="product.id" :value="product" v-for="product in products">{{ product.name }}</option>
            </select>
          </div>
        </div>

        <div class="form-group" v-if="product">
          <label class="col-sm-2 control-label"></label>
          <div class="col-sm-10 product">
            <strong>{{ product.name }}</strong> <br>
            <span class="is-warning" v-if="product.restricted"><small>Afgeschermd product</small><br></span>
            <strong>Prijs: &euro; {{ product.price }}</strong>
            <span>{{ product.description }}</span>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-2 control-label">Bedrag</label>
          <div class="col-sm-10">
            <input class="form-control" placeholder="Bedrag van de bestelling" type="text"
                   v-model="predetermined_budget">
            <span v-show="product_order && product_order.distribution_order" class="is-warning">
              Dit product is vastgelegd via verdeelsleutel. Aanpassing zullen aan alle potjes van deze verdeelsleutel
              worden doorgevoerd.
            </span>
          </div>
        </div>
        <div class="form-group" v-if="user.is_staff">
          <label class="col-sm-2 control-label">Verdeelsleutel</label>
          <div class="col-sm-10">
            <select :disabled="product_order && product_order.id" class="form-control" v-model="distribution">
              <option value="0">Geen verdeelsleutel bestelling</option>
              <option :key="distribution.id" :value="distribution" v-for="distribution in distributions">
                {{ distribution.department }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">Opmerkingen</label>
          <div class="col-sm-10">
            <textarea class="form-control" name="comments" rows="5" v-model="description"></textarea>
          </div>
        </div>
        <div class="form-group">
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { startLoadingModal, errorModal, stopLoadingModal } from '@/modalMessages'
import Modal from '../iam/Modal'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'NewProductOrderModal',
  components: { Modal },
  props: ['strategic_level', 'budget', 'product_order'],
  created () {
    this.loadDistributions()
    this.loadCategories()
  },
  data () {
    return {
      category: null,
      product: null,
      predetermined_budget: 0,
      description: '',
      distribution: 0
    }
  },
  watch: {
    product_order () {
      if (this.product_order && this.product_order.id) {
        this.category = this.product_order.product.category
        this.product = this.product_order.product
        this.predetermined_budget = this.product_order.predetermined_budget
        this.description = this.product_order.description
      }
    },
    predetermined_budget () {
      this.predetermined_budget = String(this.predetermined_budget).replace(',', '.')
    }
  },
  computed: {
    ...mapGetters('budget', ['getDistributions', 'getCategories', 'getProducts']),
    ...mapState('account', ['user']),
    title () {
      if (this.product_order && this.product_order.id) return 'Product vastlegging bewerken'
      return 'Product vastleggen'
    },
    distributions () {
      return this.getDistributions()
    },
    categories () {
      if (this.strategic_level) {
        return this.getCategories({ strategic_level: this.strategic_level.type.id })
      }
      return null
    },
    products () {
      if (this.category) {
        return this.getProducts({ category: this.category.id })
      }
      return null
    }
  },
  methods: {
    ...mapActions('budget', [
      'loadCategories',
      'loadProducts',
      'loadDistributions',
      'saveProductOrder',
      'loadProductOrders',
      'loadBudget'
    ]),

    show () {
      if (this.strategic_level) {
        this.loadProducts({ strategic_level: this.strategic_level.type.id })
      }
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    handleSaveProductOrderError () {
      return Promise.reject(new Error('Kan budget niet vastleggen, gelieve opnieuw te proberen'))
    },
    handleReloadProductOrdersSuccess () {
      stopLoadingModal()
      this.hide()
    },
    async reloadProductOrders () {
      const loadProductOrdersPromise = this.loadProductOrders(this.budget.id)
      const loadBudgetPromise = this.loadBudget(this.budget.id)
      await Promise.all([loadProductOrdersPromise, loadBudgetPromise]).then(
        () => {
          return Promise.resolve()
        },
        () => {
          return Promise.reject(new Error('Kan budgetgegevens niet herladen, gelieve de pagina manueel te herladen'))
        }
      )
    },
    async determineBudget () {
      const predeterminedBudget = parseFloat(this.predetermined_budget)
      if (isNaN(predeterminedBudget)) {
        errorModal("De waarde opgeven bij 'bedrag' is geen correct bedrag")
        return
      }
      const order = {
        strategic_level: this.strategic_level.id,
        product: this.product.id,
        predetermined_budget: this.predetermined_budget,
        description: this.description
      }
      if (this.distribution) {
        order.distribution = this.distribution.id
      }
      if (this.product_order && this.product_order.id) {
        order.id = this.product_order.id
      }
      startLoadingModal('Het budget wordt vastgelegd...')
      await this.saveProductOrder({ order: order })
        .then(this.reloadProductOrders, this.handleSaveProductOrderError)
        .then(this.handleReloadProductOrdersSuccess)
        .catch(e => errorModal(e.message))
    }
  }
}
</script>

<style scoped>
  .product {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .is-warning {
    color: orangered;
  }
</style>
