<template>
  <Page>
    <span slot="title">{{ title }}</span>
    <span slot="actionbar" v-if="budget" class="row">
        <div class="col-sm-6">
          <dl class="dl-horizontal margin-bottom-0">
            <dt>Jaarbudget</dt>
            <dd>&euro; {{ budget.predetermined_budget|currency }}</dd>
            <dt>Gereserveerd in potjes</dt>
            <dd>&euro; {{ budget.reserved_budget|currency }}</dd>
            <dt>Resterend saldo</dt>
            <dd>&euro; {{ budget.unused_budget|currency }}</dd>
          </dl>
        </div>
        <div class="col-sm-6">
          <dl class="dl-horizontal margin-bottom-0" v-if="user.is_staff">
            <dt>Beschikbaar</dt>
            <dd>&euro; {{ budget.available_budget|currency }}</dd>
          </dl>
        </div>
    </span>

    <div v-if="budget">
      <div class="row">
        <div class="col-md-2">
          <div :key="level.id" v-for="level in budget.strategic_levels">
            <Panel :is-collapsible="false" :title="level.type.name">
            <span slot="toolbar-buttons">
              <a @click="showStrategicLevelModal(level)"
                 class="btn btn-xs btn-default"
                 v-show="user.is_staff"
              >details</a>
              <a :class="[selectedLevel && selectedLevel.id === level.id ? 'btn-primary' : 'btn-default']"
                 @click="selectLevel(level)"
                 class="btn btn-xs btn-link"
              ><i class="fa fa-eye"></i> selecteren</a>
            </span>
              <table class="table">
                <tr>
                  <td>Jaarbudget</td>
                  <td>&euro; {{ level.predetermined_budget | currency }}</td>
                </tr>
                <tr>
                  <td>Gereserveerd in potjes</td>
                  <td>&euro; {{ level.reserved_budget | currency }}</td>
                </tr>
                <tr>
                  <td>Resterend saldo</td>
                  <td>&euro; {{ level.unused_budget | currency }}</td>
                </tr>
              </table>
            </Panel>
          </div>
        </div>

        <div class="col-md-4">
          <Panel :is-collapsible="false" title="Producten">
          <span slot="toolbar-buttons">
            <a @click="showNewProductOrderModal" class="btn btn-xs btn-default" v-show="selectedLevel">Nieuw</a>
            <a :class="{'btn-primary': showRestrictedProducts, 'btn-default': !showRestrictedProducts}"
               @click="showRestrictedProducts=!showRestrictedProducts"
               class="btn btn-xs">
              <i class="fa fa-eye"></i> Groepsbestellingen
            </a>
          </span>
            <div class="scroller">
              <input class="form-control input-sm margin-bottom-10" placeholder="zoeken" type="text" v-model="query">

              <div :class="orderPanelClass(order)" :key="order.id"
                   @click="selectedProductOrder=order"
                   class="panel"
                   v-for="order in productOrders">
                <div class="panel-heading">
                  {{ order.product.name }} - {{ order.product.category.name }}
                </div>
                <div class="panel-body order-information">
                  <div class="order-details">
                    <dl class="dl-horizontal">
                      <dt>Budget in potje</dt>
                      <dd>&euro; {{ order.predetermined_budget|currency }}</dd>
                      <dt>Besteld bedrag</dt>
                      <dd>&euro; {{ order.total_amount_ordered|currency }}</dd>
                      <dt>Resterend bedrag</dt>
                      <dd :class="order.available_budget < 0 ? 'text-danger' : ''">
                        &euro; {{ order.available_budget|currency }}
                      </dd>
                    </dl>
                  </div>
                  <div class="order-actions">
                    <a @click="editProductOrderHandler(order)" class="btn btn-default btn-xs btn-block"
                       v-show="canEditOrDelete(order)">
                      <i class="fa fa-pencil" /> bewerken
                    </a>
                    <a @click="viewProductOrderHandler(order)" class="btn btn-success btn-xs btn-block">
                      <i class="glyphicon glyphicon-info-sign"></i> details
                    </a>
                    <a @click="deleteProductOrderHandler(order)" class="btn btn-warning btn-xs btn-block"
                       v-show="canEditOrDelete(order)">
                      <i class="glyphicon glyphicon-trash"></i> verwijderen
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </div>
        <div class="col-md-6">
          <Panel :is-collapsible="false" title="Bestellingen">
          <span slot="toolbar-buttons">
            <a v-if="selectedProductOrder" @click="newOrder" class="btn btn-xs btn-link">Nieuw</a>
          </span>
            <div v-if="!selectedProductOrder"></div>
            <div v-else>
              <div v-show="selectedProductOrder.orders.length === 0">
                <p><em>Er zijn geen bestelling gemaakt voor dit product.</em></p>
              </div>
              <div v-show="selectedProductOrder" :class="{'bg-muted': order.closed}" :key="order.id"
                   class="order-information"
                   v-for="order in selectedProductOrder.orders">
                <div class="order-details">
                  <p><strong>{{ order.description}}</strong></p>
                  <dl class="dl-horizontal">
                    <dt>Bedrag</dt>
                    <dd>&euro; {{ order.amount|currency }}</dd>
                    <dt>Datum</dt>
                    <dd>{{ order.order_date|date-day }}</dd>
                    <dt>Gefactureerd</dt>
                    <dd>&euro; {{ order.invoiced_amount|currency }}</dd>
                    <template v-if="order.closed">
                      <dt>Afgesloten</dt>
                      <dd><i class="glyphicon glyphicon-ok"></i></dd>
                    </template>
                  </dl>
                </div>
                <div class="order-actions">
                  <a @click="editOrderHandler(order)" class="btn btn-default btn-xs btn-block"
                     v-show="canEditOrDelete(order)">
                    <i class="fa fa-pencil"></i> bewerken
                  </a>
                  <a @click="viewOrderHandler(order)" class="btn btn-success btn-xs btn-block">
                    <i class="glyphicon glyphicon-info-sign"></i> details
                  </a>
                  <a @click="deleteOrderHandler(order)" class="btn btn-warning btn-xs btn-block"
                     v-show="canEditOrDelete(order)">
                    <i class="glyphicon glyphicon-trash"></i> verwijderen
                  </a>
                  <a @click="reOpenOrderHandler(order)" class="btn btn-default btn-xs btn-block"
                     v-show="canReOpenOrder(order)">
                    <i class="glyphicon glyphicon-lock"></i> openzetten
                  </a>
                </div>
              </div>
            </div>
          </Panel>
        </div>
      </div>
      <StrategicLevelModal :budget="budget" :strategic-level="selectedLevel"
                           ref="strategicLevelModal"></StrategicLevelModal>
      <NewProductOrderModal :budget="budget" :product_order="selectedProductOrder" :strategic_level="selectedLevel"
                            ref="newProductOrderModal"></NewProductOrderModal>
      <NewOrderModal :budget="budget" :order="selectedOrder" :productOrder="selectedProductOrder"
                     ref="newOrderModal"></NewOrderModal>
      <ProductOrderDetailModal :productOrder="selectedProductOrder"
                               ref="productOrderDetailModal"></ProductOrderDetailModal>
      <OrderDetailModal :order="selectedOrder"
                        ref="orderDetailModal"></OrderDetailModal>
    </div>
  </Page>
</template>

<script>
import Panel from '@/components/iam/Panel'
import NewProductOrderModal from '@/components/budget/NewProductOrderModal'
import NewOrderModal from '@/components/budget/NewOrderModal'
import Page from '@/components/iam/Page'
import OrderDetailModal from '@/components/budget/OrderDetailModal'
import ProductOrderDetailModal from '@/components/budget/ProductOrderDetailModal'
import StrategicLevelModal from '@/components/budget/StrategicLevelModal'
import { stopLoadingModal, startLoadingModal, warningModal, questionModal, errorModal } from '@/modalMessages'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'BudgetView',
  components: {
    StrategicLevelModal,
    ProductOrderDetailModal,
    OrderDetailModal,
    Page,
    NewProductOrderModal,
    NewOrderModal,
    Panel
  },
  data () {
    return {
      selectedProductOrder: null,
      selectedOrder: null,
      selectedLevel: null,
      query: '',
      showRestrictedProducts: true
    }
  },
  async created () {
    await this.loadBudget(this.$route.params.id)
    await this.loadProductOrders(this.$route.params.id)
  },
  computed: {
    ...mapGetters('budget', ['getBudget', 'getProductOrdersForLevel']),
    ...mapState('account', ['user']),
    title () {
      if (this.budget) return `Budget ${this.budget.year} ${this.budget.office.reference}`
      return 'Budget wordt geladen...'
    },
    budget () {
      return this.getBudget(this.$route.params.id)
    },
    productOrders () {
      let orders = this.getProductOrdersForLevel(this.selectedLevel)
      if (this.query) {
        orders = orders.filter((order) => {
          return order.product.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1 ||
              order.product.category.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1
        })
      }
      if (orders && orders.length > 0 && !this.showRestrictedProducts) {
        return orders.filter((order) => order.product.restricted === false)
      }
      return orders
    }
  },
  watch: {
    /**
       * We need to watch productOrders to set the selectedProductOrder again
       */
    productOrders () {
      if (this.selectedProductOrder) {
        this.selectedProductOrder = this.productOrders.find((order) => order.id === this.selectedProductOrder.id)
      }
    },
    budget () {
      if (this.budget && !this.selectedLevel) {
        this.selectedLevel = this.budget.strategic_levels[0]
      }
    }
  },
  methods: {
    ...mapActions('budget', ['deleteProductOrder', 'loadProductOrders', 'loadBudget', 'reOpenOrder', 'deleteOrder']),

    orderPanelClass (order) {
      if (this.selectedProductOrder && this.selectedProductOrder.id === order.id) return 'panel-primary'
      return 'panel-default'
    },
    selectLevel (level) {
      this.selectedLevel = level
      this.selectedProductOrder = null
    },
    showNewProductOrderModal () {
      this.selectedProductOrder = null
      this.$refs.newProductOrderModal.show()
    },
    showNewOrderModal () {
      this.$refs.newOrderModal.show()
    },
    showProductOrderDetailModal () {
      this.$refs.productOrderDetailModal.show()
    },
    showOrderDetailModal () {
      this.$refs.orderDetailModal.show()
    },
    showStrategicLevelModal (level) {
      this.selectedLevel = level
      this.$refs.strategicLevelModal.show()
    },
    newOrder () {
      this.selectedOrder = null
      this.showNewOrderModal()
    },
    editProductOrderHandler (order) {
      this.selectedProductOrder = order
      this.showNewProductOrderModal()
    },
    viewProductOrderHandler (order) {
      this.selectedProductOrder = order
      this.showProductOrderDetailModal()
    },
    handleDeleteProductOrdersError () {
      return Promise.reject(new Error('Er ging iets mis bij het verwijderen van de bestelling, gelieve opnieuw te proberen'))
    },
    handleDeleteOrderError () {
      return Promise.reject(new Error('Er ging iets mis bij het verwijderen van de bestelling, gelieve opnieuw te proberen'))
    },
    handleReopenError () {
      return Promise.reject(new Error('Er ging iets mis bij het heropenen bestelling, gelieve opnieuw te proberen'))
    },
    async reloadProductOrders () {
      const loadProductOrdersPromise = this.loadProductOrders(this.budget.id)
      const getBudgetPromise = this.getBudget(this.budget.id)
      await Promise.all([loadProductOrdersPromise, getBudgetPromise]).then(
        () => {
          return Promise.resolve()
        },
        () => {
          return Promise.reject(new Error('Er ging iets mis bij het herladen van de bestellingen, gelieve de pagina manueel te herladen'))
        }
      )
    },
    async deleteProductOrderHandler (order) {
      const result = await questionModal('Weet je zeker dat je dit product wil verwijderen? <strong>OPGELET</strong> Alle bestellingen gelinkt aan dit product voor dit budget zullen worden verwijderd.')
      if (!result.value) return
      startLoadingModal('De bestelling wordt verwijderd...')

      await this.deleteProductOrder(order.id)
        .then(this.reloadProductOrders, this.handleDeleteProductOrdersError)
        .then(() => stopLoadingModal())
        .catch((e) => errorModal(e.message))
    },
    editOrderHandler (order) {
      this.selectedOrder = order
      if (order.distribution_order) {
        warningModal('Deze bestelling is aangemaakt via een verdeelsleutel en kan niet aangepast worden. De enige manier is de bestelling te verwijderen en deze opnieuw aan te maken.')
        return
      }
      if (order.invoices.length > 0) {
        warningModal('Deze bestelling is reeds gefactureerd en kan niet aangepast worden.')
        return
      }
      this.showNewOrderModal()
    },
    viewOrderHandler (order) {
      this.selectedOrder = order
      this.showOrderDetailModal()
    },
    canEditOrDelete (order) {
      if (this.user.is_staff) {
        return true
      }
      return !order.created_by.is_staff
    },
    async deleteOrderHandler (order) {
      if (order.invoices.length > 0) {
        warningModal('Deze bestelling is reeds gefactureerd en kan niet verwijderd worden.')
        return
      }
      const result = await questionModal('Weet je zeker dat je deze bestelling wil verwijderen? <strong>OPGELET</strong> Als deze bestelling deel uitmaakt van een verdeelsleutelbestelling zullen alle bestelling gelinkt aan deze verdeelsleutel worden verwijderd.')
      if (!result.value) return

      startLoadingModal('De bestelling wordt verwijderd...')
      await this.deleteOrder(order.id)
        .then(this.reloadProductOrders, this.handleDeleteOrderError)
        .then(() => stopLoadingModal())
        .catch((e) => errorModal(e.message))
    },
    async reOpenOrderHandler (order) {
      const result = await questionModal('Weet je zeker dat je deze bestelling terug wil openzetten?')
      if (!result.value) return
      startLoadingModal('De bestelling wordt terug opengezet...')
      await this.reOpenOrder(order.id)
        .then(this.reloadProductOrders, this.handleReopenError)
        .then(() => stopLoadingModal())
        .catch(e => errorModal(e.message))
    },
    canReOpenOrder (order) {
      return order.closed && this.user.is_staff
    }
  }
}
</script>

<style scoped>
  .scroller {
    height: 80vh;
    padding-right: 15px;
    overflow-y: scroll;
  }

  .panel-body {
    padding: 5px 0 0 0;
  }

  .panel-body .dl-horizontal {
    margin-bottom: 5px;
  }

  .order-information {
    display: flex;
    padding: 10px;
  }

  .order-information:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }

  .order-details {
    flex-grow: 2;
  }

  .order-actions {
    display: none;
  }

  .order-information:hover > .order-actions {
    display: flex;
    flex-direction: column;
  }
</style>
